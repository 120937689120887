import {
  StatusIndicatorRed,
  StatusIndicatorGreen,
  StatusIndicatorOrange,
} from '../../../assets/icons';

export const getStatus = (index: number, classname: string) => {
  switch (index) {
    case 0:
      return <StatusIndicatorRed className={classname} />;
    case 1:
      return <StatusIndicatorOrange className={classname} />;
    case 2:
      return <StatusIndicatorGreen className={classname} />;
    default:
      return '';
  }
};

const StatusBadge = ({score}: { score: number }) => {
  return (
    <div className="status-badge-container flex justify-between items-center">
      <span className="status-badge-title">Status</span>
      {getStatus(score, 'status-badge-icon')}
    </div>
  );
};

export default StatusBadge;
