import {Link, NavLink} from 'react-router-dom';
import RoundedImage from 'components/elements/roundedImage';
import {
  VIconDataReport,
  VIconHouse,
  // VIPressureBoxed
} from '../assets/icons';
import logo from 'assets/images/logo.png';
import {Iprops} from 'model/account';
import './base.style.css';

const imgStyle = {height: 30, width: 'auto'};
const logoStyle = {height: 36, width: 'auto'};
const sideBarLinks = [
  {link: '/home', icon: <VIconHouse style={imgStyle} />},
  {link: '/report', icon: <VIconDataReport style={imgStyle} />},
  // {link: '/pressure', icon: <VIPressureBoxed style={imgStyle} />},
];

const bottomNavLinks = [
  {link: '/home', icon: <VIconHouse style={imgStyle} />},
  {link: '/report', icon: <VIconDataReport style={imgStyle} />},
  // {link: '/pressure', icon: <VIPressureBoxed style={imgStyle} />},
];

const SideNav = () => {
  return (
    <div
      className="v-sidebar-desktop bg-bgBlack h-screen w-24"
      style={{width: 110}}
    >
      <div className="flex justify-center items-center h-20">
        <Link to="/">
          <img style={logoStyle} src={logo} alt="" />
        </Link>
      </div>
      <div className="v-sidebar-nav flex flex-col items-center justify-between">
        <div className="flex flex-col">
          {sideBarLinks.map(({link, icon}) => (
            <div
              key={link}
              className="flex items-center w-full justify-center h-24"
            >
              <NavLink
                exact
                to={link}
                className="icon-default-side"
                activeClassName="icon-active-side"
              >
                {icon}
              </NavLink>
            </div>
          ))}
        </div>
        {/* <div className="bottom-nav">
          <div className="flex items-center w-full justify-center h-20">
            <NavLink
              exact
              to="/question-q"
              className="icon-default-side"
              activeClassName="icon-active-side"
            >
              <VISettings />
            </NavLink>
          </div>
          <div className="flex items-center w-full justify-center h-20">
            <NavLink
              exact
              to="/question-q"
              className="icon-default-side"
              activeClassName="icon-active-side"
            >
              <VIconQuestion />
            </NavLink>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export const BottomNav = ({firstName, image}: Iprops) => {
  return (
    <div className="flex justify-between items-center w-full my-2">
      {bottomNavLinks.map(({link, icon}) => (
        <div key={link} className="flex items-center justify-center">
          <NavLink exact to={link} activeClassName="icon-active-bottom">
            {icon}
          </NavLink>
        </div>
      ))}
      <div className="flex items-center justify-center">
        <RoundedImage
          src={image}
          alt={firstName}
          style={{width: 25, height: 25}}
        />
      </div>
    </div>
  );
};

export default SideNav;
