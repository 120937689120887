import Card from '../../../components/elements/card';
import Typography from '../../../components/elements/typography';
// import Input from '../../../components/elements/inputs';
import './base.style.css';
import {representTableData} from '../../../util';
import {VIconErrorTriangle} from '../../../assets/icons';
import RollOver from '../home/components/rollOver';

const iconFunc = (name: string) => {
  switch (name) {
    default:
      return <VIconErrorTriangle style={{width: 20, color: '#F4A335'}} />;
  }
};

const data = representTableData(
    [
      {
        heading: 'warning',
        date: 'February 14, 2022',
        title: 'Chlorine Calibration',
        location: 'ZONE 4 | 181 Prospect',
        details:
        'Last chlorine calibration occurred November 14, 2021. Readings may not be accurate.',
        action: 'Replace bearing',
      },
      {
        heading: 'warning',
        date: 'February 14, 2022',
        title: 'Chlorine Calibration',
        location: 'ZONE 4 | 181 Prospect',
        details:
        'Last chlorine calibration occurred November 14, 2021. Readings may not be accurate.',
        action: 'Replace bearing',
      },
    ],
    (res) => {
      return {
        heading: (
          <Typography
            className="uppercase font-bold flex items-center"
            style={{color: res.heading === 'warning' ? '#F4A335' : '#74A343'}}
          >
            <span className={'mr-3'}>{iconFunc(res.heading)}</span> {res.heading}
          </Typography>
        ),
      };
    },
);
const InsightField = ({title, value}: { title: string; value: string }) => (
  <div className="insights-field">
    {title}: <span className="insights-field-value">{value}</span>
  </div>
);

const Insights = () => {
  return (
    <Card maxHeight={420} className="small-card-first insights-card">
      <div className="card-header" style={{marginBottom: '1rem'}}>
        <div className={'card-header-section1'}>
          <RollOver text="This section shares the recommendations and correlations that Varuna’s tool has generated from our analytics/algorithms.">
            <Typography
              size="sub"
              className="text-normal-blue uppercase font-bold whitespace-nowrap"
            >
              insights
            </Typography>
          </RollOver>
        </div>
      </div>
      <div
        className="insights-table"
        style={{padding: '1rem 0', height: 'calc(100% - 50px)'}}
      >
        <div className="overflow-auto v-card-table">
          {data.map((insight, i) => (
            <div className="mb-5" key={i}>
              <div className="flex insight-heading flex-col items-start">
                <div className="insights-header">{insight.heading}</div>
                <div className="insights-title">{insight.title}</div>
                <InsightField title="DATE" value={insight.date} />
                <InsightField title="LOCATION" value={insight.location} />
                <InsightField title="DETAILS" value={insight.details} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Insights;
