import React, {useEffect, useState, useCallback} from 'react';
// import Card from '../../../../components/elements/card';
import Typography from '../../../../components/elements/typography';
import {representTableData} from '../../../../util';
import Table from '../../../../components/table';
import {ISensor} from 'model/waterStation';
import {getStatus} from '../../components/statusBadge';
// import {AxiosResponse} from 'axios';
import {ISensorStatus} from 'model/status';
// import {getSensorStatusByID} from 'api/status';
import {statusResponseData} from '../../../../constants';
// import SensorSearchBar from 'components/sensorSearchBar';

interface Ilist {
  number: string;
  location: string;
  score: 0 | 1 | 2;
}
const heading: Array<{
  title: string;
  description: string;
}> = [
  {
    title: 'Location',
    description:
      'This section displays a list of all locations and the status of each.',
  },
  {title: 'Number', description: ''},
  {title: 'Status', description: ''},
];
interface Iprops {
  sensors: ISensor[];
  setActiveSensor: (index: number) => void;
}

const getSensorData = (list: Ilist[]) =>
  representTableData(list, (res, key) => {
    return {
      Location: <Typography className="table-text">CW0{key + 1}</Typography>,
      Number: <Typography className="table-text">{res.number}</Typography>,
      Status: getStatus(res.score, 'status-icon'),
    };
  });

const Sensors = ({sensors}: Iprops) => {
  const [tableList, setTable] = useState<Ilist[]>([]);

  const calculateStatusScore = (status: ISensorStatus) => {
    const isCommunicationGood =
      status.data.communication.online.status &&
      status.data.communication.transmission.status;
    const isPerformanceGood =
      status.data.performance.flow.status &&
      status.data.performance.pressure.status;

    let statusScore: 0 | 1 | 2 | 5 = 5;
    if (isCommunicationGood && isPerformanceGood) {
      statusScore = 2;
    } else if (!isCommunicationGood && !isPerformanceGood) {
      statusScore = 0;
    } else {
      statusScore = 1;
    }
    return statusScore;
  };

  const fetchSensorStatusByID = async (id: number) => {
    // mock response- demo environment
    return statusResponseData;

    // live environment

    // const status: AxiosResponse<ISensorStatus> =
    // await getSensorStatusByID(id);
    // return status.data;
  };

  const getAllSensorsStatus = useCallback(() => {
    const sensorListStatusPromise: Promise<Ilist>[] = sensors.map(
        async (sensor) => {
          const sensorStatus: ISensorStatus = await fetchSensorStatusByID(
              sensor.id,
          );
          const statusScore: 0 | 1 | 2 | 5 = calculateStatusScore(sensorStatus);
          return {
            number: sensor.water_utility.water_system_id,
            location: sensor.location.city,
            score: statusScore,
          };
        },
    );
    return sensorListStatusPromise;
  }, [sensors]);

  const makeTable = useCallback(async () => {
    const sensorListStatusPromise: Promise<Ilist>[] = getAllSensorsStatus();
    const sensorStatusList = await Promise.all(sensorListStatusPromise);
    setTable(sensorStatusList);
  }, [getAllSensorsStatus]);

  useEffect(() => {
    makeTable();
  }, [makeTable]);

  return (
    // <Card maxHeight={420} className="small-card-last">
    <div className="v-card-table mt-9">
      <Table heading={heading} columns={getSensorData(tableList)} />
    </div>
    // </Card>
  );
};

export default Sensors;
