import {useCallback, useState, useEffect} from 'react';
import Card from '../../../../components/elements/card';
import Typography from '../../../../components/elements/typography';
import {representTableData} from '../../../../util';
import Table from '../../../../components/table';
import {Legends} from '../../../../constants';
import {getReport} from 'api/report';
import {IWaterStation} from 'model/waterStation';
import {IChemicalData, IChemicalReport} from 'model/report';
import {AxiosResponse} from 'axios';
import {Ireport} from 'model/report';
import {IchemicalList} from 'model/chemicalRecord';

const iconSize = {
  width: 35,
  height: 35,
};

const getSensorData = (data: IChemicalData[]) => {
  return representTableData(
      [
        {
          title: 'DAILY AVERAGE',
          value: data[0].average,
        },
        {
          title: 'Current',
          value: data[0].current,
        },
        {
          title: 'Max',
          value: data[0].max,
        },
        {
          title: 'Min',
          value: data[0].min,
        },
        {
          title: 'Last Flushed',
          value: data[0].lastFlushed,
        },
        {
          title: 'Below 35MPa',
          value: data[0].below35psi,
        },
      ],
      (res, key) => {
        return {
          Value: (
            <Typography
              className={key === 0 ? 'table-content-1-report' : 'table-text'}
            >
              {res.title}
            </Typography>
          ),
          Reading: (
            <Typography
              className={key === 0 ? 'table-value-1-report' : 'table-text'}
            >
              {res.value}
            </Typography>
          ),
        };
      },
  );
};

const heading: Array<{
  title: string;
  description: string;
}> = [
  {
    title: 'Value',
    description: '',
  },
  {title: 'Reading', description: ''},
];
interface Iprops {
  waterStation: IWaterStation;
}
const PressureReport = ({
  className,
  waterStation,
}: IChemicalReport & Iprops) => {
  const Pressure = Legends['pressure'];
  const [record, setRecord] = useState<IChemicalData[]>([]);

  const fetchReports = useCallback(async () => {
    try {
      const selectedSensor =
        waterStation.sensors[waterStation.activeSensorIndex];

      const response: AxiosResponse<Ireport> = await getReport({
        chemical: 'pressure',
        id: selectedSensor.id,
      });

      const data: IChemicalData = processData(response);

      setRecord([data]);
    } catch (error) {
      console.log({error});
    }
  }, [waterStation.activeSensorIndex, waterStation.sensors]);

  const processData = (response: AxiosResponse<Ireport>) => {
    const chemical: IchemicalList = 'pressure';
    const unit: string = 'MPa';
    const data = response.data;

    const current: string = `${data.latest_data} ${unit}`;
    const avg: string = `${data.avg} ${unit}`;
    const max: string = `${data.max} ${unit}`;
    const min: string = `${data.min} ${unit}`;
    const below05Count: string = `${data.below_05_count} ${unit}`;
    const above25Count: string = `${data.above_25_count} ${unit}`;

    const newData: IChemicalData = {
      chemical,
      current,
      max,
      min,
      average: avg,
      below05Count,
      above25Count,
    };
    return newData;
  };

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      fetchReports();
    }
  }, [fetchReports, waterStation.sensors.length]);

  return (
    <Card maxHeight={420} className={className}>
      <div className="card-header">
        <div className="card-header-section1 flex justify-start">
          <Pressure.Icon style={iconSize} className={Pressure.theme} />
          <Typography
            style={{marginRight: 40, textTransform: 'capitalize'}}
            size="sub"
            className="table-header-title-black uppercase font-bold whitespace-nowrap"
          >
            Pressure (20MPa - 80MPa)
          </Typography>
        </div>
      </div>
      <div className="v-card-table">
        {record.length > 0 ? (
          <Table heading={heading} columns={getSensorData(record)} />
        ) : (
          ''
        )}
      </div>
    </Card>
  );
};

export default PressureReport;
