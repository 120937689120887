import React, {useState, useEffect} from 'react';
import GaugeChart from 'react-gauge-chart';
import Dropdown from 'components/dropdown';
import {IChemicalData} from 'model/report';
import Card from '../../../../components/elements/card';
import {getScoreByChemical, getGrade} from '../../../../util';
import Typography from '../../../../components/elements/typography';
import {compareOptions} from '../../../../constants';
import './base.style.css';

interface Iprops {
  chemicalRecords: IChemicalData[];
}

const WaterQuality = ({chemicalRecords}: Iprops) => {
  const [waterQuality, setWaterQuality] = useState<number>(0);
  const chemicalInterval: string[] = Object.keys(compareOptions);
  const [
    selectedInterval,
    // , setSelectedInterval
  ] = useState<number>(1);
  const chartStyle = {
    width: '95%',
    height: 20,
  };

  const calculateScore = (chemicalRecords: IChemicalData[]) => {
    const reducer = (prev: number, curr: IChemicalData) => {
      const currentValue = getScoreByChemical(
          curr.chemical,
          Number(curr.current.split(' ')[0]),
      );
      return prev + currentValue;
    };
    const score = chemicalRecords.reduce(reducer, 0);
    const maxScorePossible = chemicalRecords.length * 2;

    const percantageScore = Math.abs((score / maxScorePossible) * 100);
    const waterQuality =
      score < -0.1 ? Math.abs(percantageScore - 50) : percantageScore;

    setWaterQuality(waterQuality);
  };
  useEffect(() => {
    if (chemicalRecords.length > 0) {
      calculateScore(chemicalRecords);
    }
  }, [chemicalRecords]);

  return chemicalRecords.length > 0 ? (
    <Card maxHeight={450} className="small-card-first-r">
      <div className="card-header">
        <div className="card-header-section1 black-drop-down flex justify-between">
          <Typography
            size="sub"
            className="text-normal-blue uppercase font-bold whitespace-nowrap"
          >
            Water Quality
          </Typography>

          <Dropdown
            tabIndex={3}
            style={{width: 200, zIndex: 100000, pointerEvents: 'none'}}
            list={chemicalInterval}
            value={chemicalInterval[selectedInterval]}
            // onChange={setSelectedInterval}
          />
        </div>
      </div>
      <div>
        <Typography.Heading className="uppercase text-blue font-bold">
          {getGrade(waterQuality)}
        </Typography.Heading>
        <div className="grid grid-cols-fr md:grid-cols-frAuto mt-6">
          <Typography>
            A total of {waterQuality.toFixed(2)}% of the maximum allowable
            contaminants were found in the water during the last testing 24
            hours.
          </Typography>
          <div>
            <GaugeChart
              nrOfLevels={5}
              colors={[
                '#c8deeb',
                '#95c2da',
                '#62abca',
                '#0198be',
                '#F5CD19',
                '#0088b2',
              ]}
              percent={waterQuality / 100}
              arcPadding={0}
              hideText
              arcWidth={0.7}
              cornerRadius={0}
              style={chartStyle}
            />
          </div>
        </div>
      </div>
    </Card>
  ) : (
    <></>
  );
};

export default WaterQuality;
