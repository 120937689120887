import {useState, useEffect, useCallback, useMemo, ChangeEvent} from 'react';
import Typography from '../../../../components/elements/typography';
import Card from '../../../../components/elements/card';
import MyResponsiveLine from 'components/compareChart';
import Overview from './overview';
import {IkeyValues} from 'model/home';
import Dropdown from 'components/dropdown';
import './base.style.css';
import {
  chemicalColorsObject,
  compareOptions,
  statusResponseData,
} from '../../../../constants';
import moment from 'moment';
import {
  IchartData,
  Ichart,
  IchemicalList,
  IChemicalMeasurements,
} from 'model/chemicalRecord';
import {AxiosResponse} from 'axios';
import {ISensorStatus} from 'model/status';
import {IcompareChart} from 'model/compare';
import {getSensorStatusByID} from 'api/status';
import {IWaterStation} from 'model/waterStation';
import {getChemicalRecordByInterval} from 'api/chemicalRecord';
import {
  //  Legends
  chemicals,
} from '../../../../constants';
import {Ivalue} from 'model/home';
import {convertFromCelciusToFarenheight} from '../../../../util';
import {fillToNow} from '../../components/utils/compare';
import StatusBadge from 'pages/dashboard/components/statusBadge';
import RollOver from './rollOver';

// import {Ilegend} from 'model/home';

// const IconSize = {
//   width: 30,
//   height: 30,
// };

const Compare = ({waterStation}: { waterStation: IWaterStation }) => {
  const [selected, setSelected] = useState<number>(0);
  const chemicalInterval: string[] = Object.keys(compareOptions);
  const [graphData, setGraphData] = useState<Ichart>({} as Ichart);
  const selectedInterval: IkeyValues = useMemo(
      () => chemicalInterval[selected] as IkeyValues,
      [selected, chemicalInterval],
  );
  const [sensorStatusScore, setStatusScore] = useState<0 | 1 | 2 | 5>(5);
  const [sensorsLocation, setSensorsLocation] = useState<string[]>([]);
  const [displayChemicalMeasurement, setChemicalMeasurementDisplay] = useState<{
    // eslint-disable-next-line
    [k in IchemicalList]: Boolean;
  }>({
    'chlorine': true,
    'ph': true,
    'turbidity': true,
    'conductivity': true,
    'water temperature': true,
    'pressure': true,
    'internal pressure': true,
    'pH': true,
  });
  const [chemicalData, setChemicalData] = useState<{
    // eslint-disable-next-line
    [k in IchemicalList]?: any;
  }>({});

  const selectedSensor = useMemo(
      () => sensorsLocation[waterStation.activeSensorIndex],
      // eslint-disable-next-line
    [waterStation.sensors, waterStation.activeSensorIndex, sensorsLocation]
  );

  const getSensorsAddress = useCallback(() => {
    const location: string[] = [];
    waterStation.sensors.forEach((sensor, key) =>
      location.push(`CW0${key + 1}`),
    );
    setSensorsLocation(location);
  }, [waterStation]);

  useEffect(() => {
    getSensorsAddress();
  }, [getSensorsAddress]);

  const chartData: IcompareChart[] = useMemo(() => {
    const chemicalList: string[] = graphData ? Object.keys(graphData) : [];

    if (chemicalList.length) {
      const datasets: IcompareChart[] = chemicalList?.map(
          (chemical: string) => {
            const chemicalIndex = chemicals.indexOf(chemical as IchemicalList);
            const unit: string =
            graphData[chemical as IchemicalList][0]?.unit ?? '';
            const formattedUnit: string = unit === 'ppm' ? 'mg/l' : unit;
            const d: IcompareChart = {
              id: 'chlorine',
              color: '',
              data: [],
              unit: '',
            };
            d['id'] = chemicals[chemicalIndex];
            d['color'] = chemicalColorsObject[chemical as IchemicalList];
            d['unit'] = formattedUnit;
            d['data'] = graphData[chemical as IchemicalList].map(
                (data: IchartData) => ({
                  x: moment(data.time).format('YYYY-MM-DD HH:mm'),
                  y:
                chemicals[chemicalIndex] === 'water temperature' ?
                  Number(convertFromCelciusToFarenheight(data.value)) :
                  Math.round(data.value * 1000) / 1000,
                }),
            );
            return d;
          },
      );
      return datasets;
    } else {
      return [];
    }
  }, [graphData]);

  const calculateStatusScore = (status: ISensorStatus) => {
    const isCommunicationGood =
      status.data.communication.online.status &&
      status.data.communication.transmission.status;
    const isPerformanceGood =
      status.data.performance.flow.status &&
      status.data.performance.pressure.status;

    if (isCommunicationGood && isPerformanceGood) {
      setStatusScore(2);
    } else if (!isCommunicationGood && !isPerformanceGood) {
      setStatusScore(0);
    } else {
      setStatusScore(1);
    }
  };

  const fetchSensorStatusByID = useCallback(async () => {
    // mock response- demo environment
    calculateStatusScore(statusResponseData);

    const selectedSensor = waterStation.sensors[waterStation.activeSensorIndex];
    const status: AxiosResponse<ISensorStatus> = await getSensorStatusByID(
        selectedSensor.id,
    );
    // api call response
    calculateStatusScore(status.data);
  }, [waterStation]);

  const setChemicalDisplayInitialState = () => {
    const measurementDisplayState = chemicals.reduce(
        (displayState: any, chemical) => {
          displayState[chemical] = true;
          return displayState;
        },
        {},
    );
    setChemicalMeasurementDisplay(measurementDisplayState);
  };

  const fetchRecords = useCallback(async () => {
    const selectedSensor = waterStation.sensors[waterStation.activeSensorIndex];
    const interval: Ivalue = compareOptions[selectedInterval];

    const requests = chemicals.map((chemical) =>
      getChemicalRecordByInterval({
        id: selectedSensor.id,
        chemical: chemical === 'pressure' ? 'internal pressure' : chemical,
        interval: interval,
      }),
    );

    const resp: AxiosResponse<IChemicalMeasurements>[] = await Promise.all(
        requests,
    );

    const reducer = (
        prev: any,
        record: AxiosResponse<IChemicalMeasurements>,
    ) => {
      let chemical: IchemicalList = `${
        record?.config?.url?.split('/')[4] ?? 'chlorine'
      }` as IchemicalList;

      if (chemical === 'internal pressure') {
        chemical = 'pressure';
      }

      if (record.data.results.records.length) {
        const recordsWithData = record.data.results.records.filter(
            (record) => record.value !== null,
        );

        return {
          ...prev,
          [chemical]: fillToNow(recordsWithData, interval, selectedInterval),
        };
      } else {
        return prev;
      }
    };

    const chartData = resp.reduce(reducer, {});

    setChemicalData(chartData);
    // eslint-disable-next-line
  }, [waterStation, selected]);

  const onChangeInterval = (index: number) => {
    setSelected(index);
  };

  const createChartData = useCallback(() => {
    if (chemicalData) {
      const chemicalList = Object.keys(chemicalData);

      const data = (chemicalList as IchemicalList[]).reduce(
          (newChemicalData: any, chemical) => {
            if (displayChemicalMeasurement[chemical]) {
              newChemicalData[chemical] = chemicalData[chemical];
            }
            return newChemicalData;
          },
          {},
      );
      setGraphData(data);
    }
  }, [chemicalData, displayChemicalMeasurement]);

  useEffect(() => {
    createChartData();
  }, [createChartData]);

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      fetchRecords();
      fetchSensorStatusByID();
      setChemicalDisplayInitialState();
    }
  }, [fetchRecords, fetchSensorStatusByID, waterStation.sensors.length]);

  const handleSensorChange = (index: number) => {
    waterStation.setActiveSensor(index);
  };

  const handleChemicalDisplaySelection = (e: ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target;
    const hasMoreThanOneChemicalOnDisplay =
      Object.values(displayChemicalMeasurement).filter(
          (display) => display === true,
      ).length > 1;

    const shouldTurnMoreOn: boolean =
      !hasMoreThanOneChemicalOnDisplay && checked;

    if (hasMoreThanOneChemicalOnDisplay || shouldTurnMoreOn) {
      setChemicalMeasurementDisplay({
        ...displayChemicalMeasurement,
        [value]: checked,
      });
    }
  };

  return (
    <Card
      className="hidden md:grid v-card lg:col-start-1 lg:col-end-3 large-card compare-chart-container"
      style={{maxHeight: 'calc(100vh - 120px)'}}
    >
      <div
        className="card-header compare-card-header grid grid-template-columns12"
        style={{zIndex: 1000000}}
      >
        <div className="card-header-section1 sensor-list-dropdown">
          <RollOver
            text="You can select a location where
          the data is coming from. Each location can display data from up to 10 sensors."
          >
            <Typography
              size="sub"
              className="text-normal-blue uppercase font-bold whitespace-nowrap"
            >
              LOCATION
            </Typography>
          </RollOver>

          <Dropdown
            tabIndex={1}
            className="small-screen-fill black-text"
            list={sensorsLocation}
            value={selectedSensor}
            onChange={handleSensorChange}
          />
        </div>
        <div className="black-drop-down pl-11 flex justify-between compare-sensor-header">
          {sensorStatusScore === 5 ? (
            <div />
          ) : (
            <RollOver text="The status indicator shows whether the sensors are providing data at the given location.">
              <StatusBadge score={sensorStatusScore} />
            </RollOver>
          )}
          <div className="flex items-center compare-list-dropdown">
            <RollOver text="You can select a time range in which to compare the data.">
              <Typography
                size="sub"
                className="text-normal-blue uppercase font-bold whitespace-nowrap mr-5"
              >
                COMPARE
              </Typography>
            </RollOver>

            <Dropdown
              tabIndex={0}
              style={{maxWidth: 267, zIndex: 100000, width: 250}}
              list={chemicalInterval}
              value={chemicalInterval[selected]}
              onChange={onChangeInterval}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-template-columns12">
        <div className="compare__legend flex flex-col">
          <Overview
            waterStation={waterStation}
            handleChemicalDisplaySelection={handleChemicalDisplaySelection}
            chemicalMeasurementDisplay={displayChemicalMeasurement}
          />
        </div>
        <div
          className="compare-graph compare-graph-minimal"
          style={{zIndex: 100000}}
        >
          {Object.keys(graphData).length ? (
            <MyResponsiveLine data={chartData} interval={selectedInterval} />
          ) : (
            ''
          )}
        </div>
      </div>
    </Card>
  );
};

export default Compare;
