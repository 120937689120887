import {useState, useEffect, useCallback, useMemo} from 'react';
import Typography from '../../../../components/elements/typography';
import Card from '../../../../components/elements/card';
import MyResponsiveLine from 'components/compareChart';
import {IkeyValues} from 'model/home';
import Dropdown from 'components/dropdown';
import './base.style.css';
import {compareOptions} from '../../../../constants';
import {IWaterStation} from 'model/waterStation';
import {
  IchartData,
  Ichart,
  IchemicalList,
  IChemicalMeasurements,
} from 'model/chemicalRecord';
import {getChemicalRecordByInterval} from 'api/chemicalRecord';
import {AxiosResponse} from 'axios';
import {IcompareChart} from 'model/compare';
import {Ivalue} from 'model/home';
import {fillToNow} from '../../components/utils/compare';
import moment from 'moment';

const chemicals: IchemicalList[] = ['pressure'];

const Compare = ({waterStation}: { waterStation: IWaterStation }) => {
  const [selected, setSelected] = useState<number>(0);
  const chemicalInterval: string[] = Object.keys(compareOptions);
  const [graphData, setGraphData] = useState<Ichart>({} as Ichart);
  const selectedInterval: IkeyValues = useMemo(
      () => chemicalInterval[selected] as IkeyValues,
      [selected, chemicalInterval],
  );

  const chartData: IcompareChart[] = useMemo(() => {
    const chemicalList: string[] = graphData ? Object.keys(graphData) : [];

    if (chemicalList.length) {
      const datasets: IcompareChart[] = chemicalList?.map(
          (chemical: string) => {
            const unit: string =
            graphData[chemical as IchemicalList][0]?.unit ?? '';
            const d: IcompareChart = {
              id: 'pressure',
              color: '',
              data: [],
              unit: '',
            };
            d['id'] = 'pressure';
            d['color'] = '#F4A335';
            d['unit'] = unit;
            d['data'] = graphData[chemical as IchemicalList].map(
                (data: IchartData) => ({
                  x: moment(data.time).format('YYYY-MM-DD HH:mm'),
                  y: Math.round(data.value * 1000) / 1000,
                }),
            );
            return d;
          },
      );
      return datasets;
    } else {
      return [];
    }
  }, [graphData]);

  const fetchRecords = useCallback(async () => {
    const selectedSensor = waterStation.sensors[waterStation.activeSensorIndex];
    const interval: Ivalue = compareOptions[selectedInterval];

    const requests = chemicals.map((chemical) =>
      getChemicalRecordByInterval({
        id: selectedSensor.id,
        chemical,
        interval: interval,
      }),
    );

    const resp: AxiosResponse<IChemicalMeasurements>[] = await Promise.all(
        requests,
    );

    const reducer = (
        prev: any,
        record: AxiosResponse<IChemicalMeasurements>,
    ) => {
      const chemical: IchemicalList = `${
        record?.config?.url?.split('/')[4] ?? 'chlorine'
      }` as IchemicalList;
      if (record.data.results.records.length) {
        return {
          ...prev,
          [chemical]: fillToNow(
              record.data.results.records,
              interval,
              selectedInterval,
          ),
        };
      } else {
        return prev;
      }
    };

    const chartData = resp.reduce(reducer, {});
    setGraphData(chartData);
    // eslint-disable-next-line
  }, [waterStation, selected]);

  const onChangeInterval = (index: number) => {
    setSelected(index);
  };

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      fetchRecords();
    }
    // eslint-disable-next-line
  }, [fetchRecords]);

  return (
    <Card
      className="hidden md:grid v-card lg:col-start-1 lg:col-end-3 large-card"
      style={{maxHeight: 'calc(100vh - 120px)'}}
    >
      <div className="card-header">
        <div className="card-header-section1 black-drop-down">
          <Typography
            size="sub"
            className="text-normal-blue uppercase font-bold whitespace-nowrap"
          >
            compare
          </Typography>

          <Dropdown
            tabIndex={6}
            style={{maxWidth: 267, zIndex: 100000}}
            list={chemicalInterval}
            value={chemicalInterval[selected]}
            onChange={onChangeInterval}
          />
        </div>
      </div>
      <div style={{width: '100%', height: '100%'}}>
        <div
          className="compare-graph compare-graph-minimal"
          style={{zIndex: 100000, width: '100%', height: '100%'}}
        >
          {Object.keys(graphData).length ? (
            <MyResponsiveLine data={chartData} interval={selectedInterval} />
          ) : (
            ''
          )}
        </div>
      </div>
    </Card>
  );
};

export default Compare;
