import {useState, useEffect, useCallback} from 'react';
import Typography from '../../../../components/elements/typography';
import Card from '../../../../components/elements/card';
import './base.style.css';
import {IWaterStation} from 'model/waterStation';
import BarChart from 'components/barchart';
import Dropdown from 'components/dropdown';
import {getChemicalRecordByInterval} from 'api/chemicalRecord';
import {IkeyValues} from 'model/home';
import {compareOptions} from '../../../../constants';
import {AxiosResponse} from 'axios';
import {IChemicalMeasurements} from 'model/chemicalRecord';

interface IBarchartData {
  location: string;
  value: number;
  color?: string;
  issue?: boolean;
  unit?: string;
  label?: string;
}
interface ISensorLevel {
  waterStation: IWaterStation;
  className?: string;
}

const SensorLevels = ({waterStation, className}: ISensorLevel) => {
  const [barChartData, setBarChartData] = useState<IBarchartData[]>([]);
  const chemicalInterval: string[] = Object.keys(compareOptions);
  const [selectedInterval, setSelectedInterval] = useState<number>(0);

  const fetchChemicalRecord = useCallback(async () => {
    if (waterStation.sensors.length > 0) {
      try {
        const requests = waterStation.sensors.map((sensor) => {
          const interval: IkeyValues = chemicalInterval[
              selectedInterval
          ] as IkeyValues;
          return getChemicalRecordByInterval({
            id: sensor.id,
            chemical: 'pressure',
            interval: compareOptions[interval],
          });
        });
        const resp: AxiosResponse<IChemicalMeasurements>[] = await Promise.all(
            requests,
        );
        const data: IBarchartData[] = processData(resp);
        setBarChartData(data);
      } catch (error) {
        console.log({error});
      }
    } // eslint-disable-next-line
  }, [selectedInterval, waterStation.sensors]);

  const processData = (resp: AxiosResponse<IChemicalMeasurements>[]) => {
    const data: IBarchartData[] = [];
    waterStation.sensors.forEach((sensor, key) => {
      const sensorData = resp[key].data;
      if (sensorData.count > 0) {
        const value = sensorData.results.records[0]?.value;
        const unit = sensorData.results.records[0]?.unit;
        const chemical = 'pressure';
        const result = {
          location: `${sensor.location.formatted_address} ${sensor.location.city}`,
          value: Math.round(value * 1000) / 1000,
          unit,
          chemical,
          label:
            sensor?.location?.street_address_1 ||
            sensor.location.formatted_address ||
            '',
        };
        data.push(result);
      }
    });
    return data;
  };

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      fetchChemicalRecord();
    }
  }, [fetchChemicalRecord, waterStation.sensors]);

  return (
    <Card
      maxHeight={420}
      className={`v-card lg:col-start-1 lg:col-end-3 large-card ${className}`}
    >
      <div className="card-header">
        <div className="card-header-section1">
          <Typography
            size="sub"
            className="text-normal-blue uppercase font-bold whitespace-nowrap"
          >
            sensor levels
          </Typography>
          <div
            className="flex items-center black-drop-down"
            style={{maxWidth: 460}}
          >
            <Dropdown
              tabIndex={8}
              style={{marginLeft: 20, width: 200, zIndex: 100000}}
              list={chemicalInterval}
              value={chemicalInterval[selectedInterval]}
              onChange={setSelectedInterval}
            />
          </div>
        </div>
      </div>
      <div
        style={{width: '100%', height: '342px', minHeight: 0, minWidth: 0}}
        className="bar-chart"
      >
        <BarChart data={barChartData} chemical="pressure" />
      </div>
    </Card>
  );
};

export default SensorLevels;
