import {useState, useEffect, useCallback} from 'react';
import Typography from '../../../../components/elements/typography';
import Card from '../../../../components/elements/card';
import './base.style.css';
import {VIconCloudyDay} from '../../../../assets/icons';
// import DateInput from '../../../components/molecules/dateInput';
import {IWaterStation} from 'model/waterStation';
import {getForecast} from 'api/weather';
import {IToday, Inext} from 'model/localTemperature';
import {convertFromCelciusToFarenheight} from '../../../../util';
import RollOver from './rollOver';

const defaultToday = {
  time: '',
  windSpeed: '',
  humidity: '',
  feelsLike: '',
  temperature: '',
  maxTemperature: '',
  minTemperature: '',
  weatherDesc: '',
};

const next = {
  weekDay: '',
  temperature: '',
};

const LocalTemp = ({waterStation}: { waterStation: IWaterStation }) => {
  // const [date, setDate] = useState<Date>(new Date());
  const [today, setToday] = useState<IToday>(defaultToday);
  const [tomorrow, setTomorrow] = useState<Inext>(next);
  const [dayAfter, setDayAfter] = useState<Inext>(next);

  const getWeatherInfo = useCallback(async () => {
    try {
      const sensor = waterStation.sensors[waterStation.activeSensorIndex];
      const forecast = await getForecast({
        lat: sensor.location.latitude,
        lon: sensor.location.longitude,
      });

      const day1 = forecast.data.list[0];
      const day2 = forecast.data.list[7];
      const day3 = forecast.data.list[15];

      const newday: IToday = {
        time: new Date().toLocaleString('en-us', {
          hour: 'numeric',
          hour12: true,
          minute: '2-digit',
        }),
        windSpeed: `${parseInt(`${day1.wind.speed}`)}m/s`,
        humidity: `${parseInt(`${day1.main.humidity}`)}%`,
        feelsLike: `${parseInt(
            `${convertFromCelciusToFarenheight(day1.main.feels_like - 273.15)}`,
        )}°`,
        temperature: `${parseInt(
            `${convertFromCelciusToFarenheight(day1.main.temp - 273.15)}`,
        )}°`,
        maxTemperature: `${parseInt(
            `${convertFromCelciusToFarenheight(day1.main.temp_max - 273.15)}`,
        )}°`,
        minTemperature: `${parseInt(
            `${convertFromCelciusToFarenheight(day1.main.temp_min - 273.15)}`,
        )}°`,
        weatherDesc: `${day1.weather[0].description}`,
      };
      setToday(newday);
      setTomorrow({
        weekDay: new Date(day2.dt_txt).toLocaleString('en-us', {
          weekday: 'short',
        }),
        temperature: `${parseInt(
            `${convertFromCelciusToFarenheight(day2.main.temp - 273.15)}`,
        )}°`,
      });
      setDayAfter({
        weekDay: new Date(day3.dt_txt).toLocaleString('en-us', {
          weekday: 'short',
        }),
        temperature: `${parseInt(
            `${convertFromCelciusToFarenheight(day3.main.temp - 273.15)}`,
        )}°`,
      });
    } catch (error) {
      console.log({error});
    }
  }, [waterStation.sensors, waterStation.activeSensorIndex]);

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      getWeatherInfo();
    }
  }, [waterStation.sensors.length, getWeatherInfo]);

  return (
    <Card className="small-card-first local-temperature-container">
      <div className="card-header">
        <div className="card-header-section1">
          <RollOver text="This section contains weather information for the city/locale where the data being viewed is associated with.">
            <Typography
              size="sub"
              className="text-normal-blue uppercase font-bold whitespace-nowrap"
            >
              local temperature
            </Typography>
          </RollOver>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              pointerEvents: 'none',
            }}
          >
            {/* <DateInput value={date} onChange={setDate} /> */}
          </div>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-3">
          <div>
            <Typography size="sm">{today.time}</Typography>
            <VIconCloudyDay className="cloud" style={{fontSize: 108}} />
            <Typography size="sm">{today.weatherDesc}</Typography>
          </div>
          <div>
            <div>
              <Typography size="sm">
                <span className="font-bold">Wind: </span>
                {today.windSpeed}
              </Typography>
              <Typography size="sm">
                <span className="font-bold">Rain: </span>
                {today.humidity}
              </Typography>
            </div>
            <Typography
              className="text-blue font-bold"
              style={{fontSize: 70, lineHeight: '90px'}}
            >
              {today.temperature}
            </Typography>
            <Typography size="sm">Feels like {today.feelsLike}</Typography>
          </div>
          <div>
            <div />
            <div className="flex flex-col items-center">
              <Typography className="text-textSoft font-bold" size="md-3">
                {today.maxTemperature}
              </Typography>
              <Typography size="sm">High</Typography>
            </div>
            <div className="flex flex-col items-center">
              <Typography className="text-textSoft font-bold" size="md-3">
                {today.minTemperature}
              </Typography>
              <Typography size="sm">low</Typography>
            </div>
          </div>
        </div>
        <div className="grid gap-3 md:grid-cols-2 gap-4 mt-4 ">
          <div className="bg-bgGrey rounded flex items-center justify-between px-6 rounded-lg">
            <Typography className="text-textGrey font-bold">
              {tomorrow.weekDay}
            </Typography>
            <VIconCloudyDay
              className="cloud"
              style={{fontSize: 80, left: 0, top: 9}}
            />
            <Typography className="text-blue font-bold" size="md-2">
              {tomorrow.temperature}
            </Typography>
          </div>
          <div className="bg-bgGrey rounded flex items-center justify-between px-6 rounded-lg">
            <Typography className="text-textGrey font-bold">
              {dayAfter.weekDay}
            </Typography>
            <VIconCloudyDay
              className="cloud"
              style={{fontSize: 80, left: 0, top: 9}}
            />
            <Typography className="text-blue font-bold" size="md-2">
              {dayAfter.temperature}
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default LocalTemp;
