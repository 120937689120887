import {IMeasurementRecord} from 'model/chemicalRecord';
import {Ivalue, ICountOption, IkeyValues} from 'model/home';
import moment from 'moment';

export const compareOptions: ICountOption = {
  'Last 1 hour': 7,
  'Last 2 hours': 14,
  'Last 4 hours': 28,
  'Last 8 hours': 8,
  'Last 12 hours': 12,
  'Last 24 hours': 24,
  'Last 2 days': 24,
  'Last 3 days': 36,
  'Last 7 days': 7,
  'Last 15 days': 15,
  'Last 30 days': 31,
  'Last 2 months': 30,
  'Last 3 month': 12,
  'Last 12 month': 12,
};

const convertToUnix = (time: string) =>
  moment(time.substring(0, 19) + '-00:00').unix();

export const fillToNow = (
    records: IMeasurementRecord[],
    interval: Ivalue,
    period: IkeyValues,
) => {
  const now: number = Math.round(Date.now() / 1000);
  const intervalValue: number =
    Number(interval[0]) === 5 ? 10 : Number(interval[0]);
  const intervalUnit: any = `${interval[1]}`.toLowerCase();
  const validatedIntervalUnit =
    intervalUnit === 't' ? 'm' : intervalUnit === 'm' ? 'M' : intervalUnit;
  const intervalInSec: number = moment
      .duration(intervalValue, validatedIntervalUnit)
      .asSeconds();

  const formatStr: string = 'YYYY-MM-DDTHH:mm:ss';
  const count: number = compareOptions[period];

  if (records.length) {
    const formattedRecordsList = records
        .map((record) => {
          const unixTime = convertToUnix(record.time);
          const time = moment.unix(unixTime).format(formatStr);
          return {
            time,
            value: record.value,
          };
        })
        .slice(0, count);
    return formattedRecordsList;
  } else {
    return new Array(count).fill(0).map((x, i) => ({
      value: 0,
      time: moment.unix(now - i * intervalInSec).format(formatStr),
    }));
  }
};
