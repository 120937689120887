import {useEffect, useState} from 'react';
import './base.style.css';
import {Bar, ComputedDatum} from '@nivo/bar';
import SafeBand from './sensorLevelsSafeBand';
import {calculateBandLevels} from './utils/compare';
import {chemicalColorsObject} from '../constants';
import {IchemicalList} from 'model/chemicalRecord';
import BarChartToolTip from './barChartTooltip';

interface BarChartProps {
  data?: any;
  chemical: IchemicalList;
}

export const BarChart = ({data, chemical}: BarChartProps) => {
  const selectedChemicalColor = chemicalColorsObject[chemical];
  const container = document.getElementById('bar-chart-container');
  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  } as any);

  const bandLevels = calculateBandLevels([
    {
      id: data[0]?.chemical,
      color: data[0]?.data?.data?.color,
      unit: data[0]?.data?.data?.unit,
      data: [],
    },
  ]);

  const getColor = (status: 'aboveUpperBand' | 'pressureLoss' | '') => {
    switch (status) {
      case 'aboveUpperBand':
        return '#B1B1B1';
      case 'pressureLoss':
        return '#FF0000';
      default:
        return selectedChemicalColor;
    }
  };

  const colorSelector = (r: ComputedDatum<any>) => {
    const isPressure = r?.data?.chemical === 'pressure';
    const value = r?.value ?? 0;
    const overUpperBand = value > bandLevels.max;
    const belowLowerband = bandLevels.min > value;
    const isPressureLoss = belowLowerband && isPressure;
    const status = overUpperBand ?
      'aboveUpperBand' :
      isPressureLoss ?
      'pressureLoss' :
      '';
    console.log({status});
    return getColor(status);
  };

  const maxValue =
    bandLevels.max > 700 ?
      bandLevels.max + 100 :
      bandLevels.max > 49 ?
      bandLevels.max + 50 :
      bandLevels.max > 19 ?
      bandLevels.max + 10 :
      bandLevels.max > 7.5 ?
      bandLevels.max + 5 :
      bandLevels.max > 1.5 ?
      bandLevels.max + 2.5 :
      bandLevels.max;
  useEffect(() => {
    setDimension({
      height: (container?.offsetHeight ?? 100) - 50,
      width: container?.offsetWidth,
    });
  }, [container, setDimension]);

  return (
    <div id="bar-chart-container">
      {data.length ? (
        <Bar
          width={dimension.width}
          height={dimension.height}
          data={data}
          enableGridX={true}
          keys={['value']}
          indexBy="location"
          valueScale={{type: 'linear'}}
          padding={0.05}
          margin={{
            top: 10,
            right: 10,
            bottom: 36,
            left: 46,
          }}
          maxValue={maxValue}
          enableLabel={true}
          borderRadius={2}
          axisLeft={{
            tickValues: 7,
            tickSize: 0,
            tickPadding: 5,
            format: (v) => `${v} ${data[0]?.unit}`,
          }}
          axisBottom={{tickValues: []}}
          axisTop={{tickValues: []}}
          axisRight={{tickValues: []}}
          colorBy="id"
          labelSkipHeight={7}
          colors={colorSelector}
          label={(d) => `${d?.data?.label}`}
          indexScale={{type: 'band', round: true}}
          layers={[SafeBand, 'grid', 'axes', 'bars', 'markers', 'legends']}
          tooltip={BarChartToolTip}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default BarChart;
