const classes = {
  0: 'rollover-container',
  1: 'rollover-container rollover-container-right ',
  2: 'rollover-container rollover-container-right rollover-container-2',
  3: 'rollover-container rollover-container-right rollover-container-3',
};
const RollOver = ({
  text,
  children,
  rightAlign = 0,
}: {
  text: string;
  children: any;
  rightAlign?: number;
}) => {
  const classIndex: '0' | '1' | '2' | '3' = `${
    (rightAlign > 3 ? 0 : rightAlign) as 0 | 1 | 2 | 3
  }`;
  const activeClass: string = classes[classIndex] || '';

  return (
    <div className="rollOver-parent">
      {children}
      {text && (
        <div className={activeClass}>
          <div className="rollover-triangle"></div>
          <div className="rollover-content">{text}</div>
        </div>
      )}
    </div>
  );
};

export default RollOver;
