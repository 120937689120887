import React, {useEffect, useState, useCallback} from 'react';
import Card from '../../../../components/elements/card';
import Typography from '../../../../components/elements/typography';
import {representTableData} from '../../../../util';
import Table from '../../../../components/table';
import {ISensor} from 'model/waterStation';
import SensorSearchBar from 'components/sensorSearchBar';

interface Ilist {
  number: string;
  location: string;
}
interface Iprops {
  sensors: ISensor[];
  setActiveSensor: (index: number) => void;
}

const heading: Array<{
  title: string;
  description: string;
}> = [
  {
    title: 'location',
    description: '',
  },
  {title: 'number', description: ''},
];

const getSensorData = (list: Ilist[]) =>
  representTableData(list, (res) => {
    return {
      location: <Typography className="table-text">{res.location}</Typography>,
      number: <Typography className="table-text">{res.number}</Typography>,
    };
  });

const Sensors = ({sensors, setActiveSensor}: Iprops) => {
  const [tableList, setTable] = useState<Ilist[]>([]);

  const makeTable = useCallback(() => {
    const list: Ilist[] = sensors.map((sensor) => ({
      number: sensor.device_id,
      location: sensor.location.street_address_1 || sensor.location.city,
    }));

    setTable(list);
  }, [sensors]);

  useEffect(() => {
    makeTable();
  }, [makeTable]);

  return (
    <Card maxHeight={420} className="small-card-last">
      <div className="card-header">
        <div className="card-header-section1">
          <Typography
            style={{marginRight: 40}}
            size="sub"
            className="text-blue uppercase font-bold whitespace-nowrap text-normal-blue"
          >
            sensors
          </Typography>
          <SensorSearchBar
            sensorList={sensors}
            inputStyle={{height: 48, maxHeight: 'auto'}}
            placeholder="search sensors"
            onChange={setActiveSensor}
          />
        </div>
      </div>
      <div className="v-card-table">
        <Table heading={heading} columns={getSensorData(tableList)} />
      </div>
    </Card>
  );
};

export default Sensors;
