import {useEffect, useCallback, useState} from 'react';
// import Insights from './insights';
import WaterQuality from './components/waterQuality';
// import Activity from './activity';
import './base.style.css';
import {IWaterStation} from 'model/waterStation';
import {IchemicalList} from 'model/chemicalRecord';
import {IChemicalData} from 'model/report';
import ChemicalReport from './components/chemicalReport';
import {getReport} from 'api/report';
import {chemicals} from '../../../constants';
import {Ireport} from 'model/report';
import {AxiosResponse} from 'axios';
import {Legends} from '../../../constants';
import {convertFromCelciusToFarenheight} from '../../../util';

// const cards = [
//   Compare,
//   Overview,
//   Measurements,
//   Insights,
//   WaterQuality,
//   Activity,
//   SensorLevels,
//   LocalTemp,
// ];

const classNames: { [key: number]: string } = {
  0: 'small-card-last-r',
  1: 'small-card-first-r',
  2: 'small-card-last-r',
  3: 'small-card-first-r',
  4: 'small-card-last-r',
  5: 'small-card-first-r',
};

const units: { [key: string]: string } = {
  'chlorine': 'mg/l',
  'ph': '',
  'water temperature': '°F',
  'turbidity': 'NTU',
  'conductivity': 'µS/cm',
};

const iconSize = {
  width: 35,
  height: 35,
};

interface Iprops {
  waterStation: IWaterStation;
}
const Home = ({waterStation}: Iprops) => {
  const [records, setRecords] = useState<IChemicalData[]>([]);
  const fetchReports = useCallback(async () => {
    try {
      const selectedSensor =
        waterStation.sensors[waterStation.activeSensorIndex];
      const requests = chemicals.map((chemical) =>
        getReport({
          chemical,
          id: selectedSensor.id,
        }),
      );

      const response = await Promise.allSettled(requests);
      const resolvedResponse: PromiseSettledResult<AxiosResponse<Ireport>>[] =
        response.filter((resp) => resp.status === 'fulfilled');

      const data: IChemicalData[] = processData(resolvedResponse);

      setRecords(data);
    } catch (error) {
      console.log({error});
    }
  }, [waterStation.activeSensorIndex, waterStation.sensors]);

  const processData = (
      resolvedResponse: PromiseSettledResult<AxiosResponse<Ireport>>[],
  ) => {
    const chemicalData: IChemicalData[] = [];
    resolvedResponse.forEach((resp) => {
      if (resp.status === 'fulfilled') {
        const chemical: IchemicalList = resp.value.config.params['chemical'];
        const unit: string = units[chemical];
        const data = resp.value.data;
        if (chemical === 'water temperature') {
          const current: string = `${convertFromCelciusToFarenheight(
              data.latest_data,
          )} ${unit}`;
          const avg: string = `${convertFromCelciusToFarenheight(
              data.avg,
          )} ${unit}`;
          const max: string = `${convertFromCelciusToFarenheight(
              data.max,
          )} ${unit}`;
          const min: string = `${convertFromCelciusToFarenheight(
              data.min,
          )} ${unit}`;
          const below05Count: string = `${convertFromCelciusToFarenheight(
              data.below_05_count,
          )} ${unit}`;
          const above25Count: string = `${convertFromCelciusToFarenheight(
              data.above_25_count,
          )} ${unit}`;
          const newData: IChemicalData = {
            chemical,
            current,
            max,
            min,
            average: avg,
            below05Count,
            above25Count,
          };
          chemicalData.push(newData);
        } else {
          const current: string = `${data.latest_data} ${unit}`;
          const avg: string = `${data.avg} ${unit}`;
          const max: string = `${data.max} ${unit}`;
          const min: string = `${data.min} ${unit}`;
          const below05Count: string = `${data.below_05_count} ${unit}`;
          const above25Count: string = `${data.above_25_count} ${unit}`;
          const newData: IChemicalData = {
            chemical,
            current,
            max,
            min,
            average: avg,
            below05Count,
            above25Count,
          };
          chemicalData.push(newData);
        }
      }
    });
    return chemicalData;
  };

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      fetchReports();
    }
  }, [fetchReports, waterStation.sensors.length]);

  return (
    <div>
      <div className="v-card-layout-2">
        <WaterQuality chemicalRecords={records} />
        {records.map((record, key) => {
          const Chemical = Legends[record.chemical];

          return (
            <ChemicalReport
              className={classNames[key]}
              key={key}
              Icon={
                <Chemical.Icon style={iconSize} className={Chemical.theme} />
              }
              chemicalTitle={Chemical.text}
              record={record}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Home;
