import {useState, useEffect, memo, useMemo} from 'react';
import Account from 'components/account';
import logo from '../assets/images/logo-full.png';
import {VIPrint} from '../assets/icons';
// import SensorSearchBar from 'components/sensorSearchBar';
import {get12hourTime} from '../util';
import Typography from 'components/elements/typography';
// import Dropdown from 'components/dropdown';
import {IUserDetails} from 'model/user';
import {IWaterStation} from 'model/waterStation';
import {useLocation} from 'react-router-dom';
import './base.style.css';

const Today = memo(({waterStationName}: { waterStationName: string }) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 30 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div
      className="hidden lg:block current-date-time"
      style={{marginRight: 30}}
    >
      <Typography
        style={{
          textAlign: 'right',
          font: 'normal normal normal 1.4rem/16px Noto Sans',
          letterSpacing: 0,
          color: '#57585A',
          opacity: 1,
        }}
      >
        <span
          style={{
            marginRight: 10,
            font: 'normal normal 600 1.3rem/16px Noto Sans',
            color: '#57585A',
          }}
          className="current-date"
        >
          {waterStationName}
        </span>
        |{' '}
        <span
          style={{marginRight: 10, marginLeft: 10}}
          className="current-date"
        >
          {date.toLocaleDateString('us', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          })}
        </span>{' '}
        |{' '}
        <span style={{marginLeft: 10}} className="lowercase current-time">
          {get12hourTime()}
        </span>
      </Typography>
    </div>
  );
});

interface Iprops {
  user: IUserDetails;
  waterStation: IWaterStation;
}

const Header = ({user, waterStation}: Iprops) => {
  const location = useLocation();
  const isReport = location.pathname.includes('/report');
  const {profile} = user;
  const selectedSensor = useMemo(
      () => waterStation.sensors[waterStation.activeSensorIndex],
      // eslint-disable-next-line
    [waterStation.sensors, waterStation.activeSensorIndex]
  );
  // const handleChange = (index: number) => {
  //   waterStation.setActiveSensor(index);
  // };

  return (
    <>
      <div
        className="sticky top-0 bg-bgBlack flex md:hidden"
        style={{minHeight: 86, zIndex: 2000000}}
      >
        <div className="v-header-top-nav flex h-auto">
          <img src={logo} alt="" style={{maxWidth: 126, width: '100%'}} />
          <div className="v-hamburger-container">
            <div className="bg-bgGrey w-10 mb-2" />
            <div className="bg-bgGrey w-10 mb-2" />
            <div className="bg-bgGrey w-10" />
          </div>
        </div>
      </div>
      <div className="px-0 md:px-10 py-0 md:py-5">
        <div className="bg-white flex items-center h-auto">
          <div className="container justify-between items-center h-auto grid header-container">
            <div
              className="flex flex-1 items-center"
              style={{zIndex: 1000001}}
            >
              <div className="small-screen-hide w-full">
                {/* <SensorSearchBar
                  sensorList={waterStation.sensors}
                  inputStyle={{
                    height: 47,
                    maxHeight: 47,
                  }}
                  placeholder="search sensors"
                  onChange={handleChange}
                /> */}
              </div>

              {isReport && (
                <VIPrint
                  onClick={window.print}
                  className="cursor-pointer small-screen-hide"
                  style={{
                    width: 35,
                    height: 35,
                    color: 'grey',
                    marginLeft: 20,
                  }}
                />
              )}
            </div>
            <div className="flex flex-1 justify-end small-screen-hide">
              <div className="grid grid-flow-col gap-4 items-center">
                <Today waterStationName={selectedSensor?.water_utility?.name} />
                <Account
                  firstName={profile.first_name}
                  lastName={profile.last_name}
                  image=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
