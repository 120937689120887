import {useEffect, useCallback, useState} from 'react';
import {Switch, useRouteMatch, Route, useLocation} from 'react-router-dom';
import Home from './home';
import DashboardLayout from './layout';
import ProtectedRoute from 'shared/protectedRoute';
import {IUserDetails} from 'model/user';
import {getSensors} from 'api/sensors';
import {IWaterStation, ISensor} from 'model/waterStation';
import {Iprofile} from 'model/profile';
import {getUserProfile} from 'api/profile';
import Notfound from 'pages/notfound';
import Report from './report';
import Pressure from './pressure';

interface IDashboard {
  user: IUserDetails;
  waterStation: IWaterStation;
}

const DashboardRoutes = ({user, waterStation}: IDashboard) => {
  const match = useRouteMatch();
  const location = useLocation();
  const [fetched, setStatus] = useState(false);

  const fetchSensors = useCallback(async () => {
    const type: 'pressure' | 'chemical' = location?.pathname?.includes(
        'pressure',
    ) ?
      'pressure' :
      'chemical';

    const res: { data: ISensor[] } = await getSensors(type);
    waterStation.setSensors(res.data);
    // eslint-disable-next-line
  }, [location?.pathname]);

  const fetchUserProfile = useCallback(async () => {
    const res: { data: Iprofile } = await getUserProfile();
    user.setProfile(res.data);
  }, [user]);

  useEffect(() => {
    if (!fetched) {
      fetchUserProfile();
      setStatus(true);
    }
  }, [fetchUserProfile, fetched]);

  useEffect(() => {
    fetchSensors();
  }, [fetchSensors]);

  return (
    <ProtectedRoute>
      <DashboardLayout user={user} waterStation={waterStation}>
        <Switch>
          <Route path={`${match.url}home`}>
            <Home waterStation={waterStation} />
          </Route>
          <Route exact path={`${match.url}report`}>
            <Report waterStation={waterStation} />
          </Route>
          <Route path={`${match.url}pressure`}>
            <Pressure waterStation={waterStation} />
          </Route>
          <Route path="*">
            <Notfound />
          </Route>
        </Switch>
      </DashboardLayout>
    </ProtectedRoute>
  );
};

export default DashboardRoutes;
