import React from 'react';
import Compare from './components/compare';
// import Overview from './components/overview';
import SensorMap from '../components/sensorMap';
import Insights from '../components/insights';
// import Sensors from './components/sensors';
// import WaterQuality from './waterQuality';
// import Activity from './activity';
import SensorLevels from './components/sensorLevels';
import LocalTemp from './components/localTemp';
// import Measurements from './components/measurements';
import './base.style.css';
import {IWaterStation} from 'model/waterStation';

interface Iprops {
  waterStation: IWaterStation;
}
const Home = ({waterStation}: Iprops) => {
  return (
    <div>
      <div className="v-card-layout">
        <Compare waterStation={waterStation} />
        {/* <Overview waterStation={waterStation} /> */}
        <SensorMap waterStation={waterStation} />
        <SensorLevels waterStation={waterStation} />
        <LocalTemp waterStation={waterStation} />
        <Insights />
        {/* <Measurements waterStation={waterStation} /> */}
        {/* <Sensors
          sensors={waterStation.sensors}
          setActiveSensor={waterStation.setActiveSensor}
        /> */}
      </div>
    </div>
  );
};

export default Home;
