import React from 'react';
import Card from '../../../../components/elements/card';
import Typography from '../../../../components/elements/typography';
import {representTableData} from '../../../../util';
import Table from '../../../../components/table';
import {IChemicalData, IChemicalReport} from 'model/report';

const getSensorData = (data: IChemicalData[]) => {
  return representTableData(
      [
        {
          title: 'Median',
          value: data[0].average,
        },
        {
          title: 'Standard deviation (SD)',
          value: data[0].current,
        },
        {
          title: 'Max',
          value: data[0].max,
        },
        {
          title: 'Min',
          value: data[0].min,
        },
        {
          title: 'Upper bound (Average + 3SD)',
          value: data[0].max,
        },
        {
          title: 'Lower bound (Average - 3SD)',
          value: data[0].min,
        },
        {
          title: '% # out of range (upper & lower bound)',
          value: `${data[0].below05Count?.split('')[0]} %`,
        },
        {
          title: '% of data below lower bound',
          value: data[0].above25Count,
        },
      ],
      (res, key) => {
        return {
          Value: (
            <Typography
              className={key === 0 ? 'table-content-1-report' : 'table-text'}
            >
              {res.title}
            </Typography>
          ),
          Reading: (
            <Typography
              className={key === 0 ? 'table-value-1-report' : 'table-text'}
            >
              {res.value}
            </Typography>
          ),
        };
      },
  );
};

const heading: Array<{
  title: string;
  description: string;
}> = [
  {
    title: 'Value',
    description: '',
  },
  {title: 'Reading', description: ''},
];

const ChemicalReport = ({
  Icon,
  record,
  className,
  chemicalTitle,
}: IChemicalReport) => {
  return (
    <Card maxHeight={450} className={className}>
      <div className="card-header">
        <div className="card-header-section1 flex justify-start">
          {Icon}
          <Typography
            style={{marginRight: 40, textTransform: 'capitalize'}}
            size="sub"
            className="table-header-title-black uppercase font-bold whitespace-nowrap"
          >
            {chemicalTitle}
          </Typography>
        </div>
      </div>
      <div className="v-card-table" style={{height: 'calc(100% - 60px)'}}>
        {record ? (
          <Table heading={heading} columns={getSensorData([record])} />
        ) : (
          ''
        )}
      </div>
    </Card>
  );
};

export default ChemicalReport;
