// import Insights from './insights';
// import WaterQuality from './waterQuality';
// import Activity from './activity';
import SensorMap from '../components/sensorMap';
import './base.style.css';
import PressureReport from './components/pressureReport';
import CompareChart from './components/compareChart';
// import {IChemicalData} from 'model/report';
import Sensors from './components/sensors';
import {IWaterStation} from 'model/waterStation';
import SensorLevel from './components/sensorLevel';
import Insights from '../components/insights';

interface Iprops {
  waterStation: IWaterStation;
}
const Pressure = ({waterStation}: Iprops) => {
  return (
    <div>
      <div className="v-card-layout">
        <SensorMap
          waterStation={waterStation}
          className="relative rounded-3xl p-8 shadow min-w-min bg-white hidden md:grid v-card lg:col-start-1 lg:col-end-3 large-card"
        />
        <PressureReport
          waterStation={waterStation}
          className="small-card-first"
        />
        <CompareChart waterStation={waterStation} />
        <Sensors
          sensors={waterStation.sensors}
          setActiveSensor={waterStation.setActiveSensor}
        />
        <SensorLevel waterStation={waterStation} />
        <Insights />
      </div>
    </div>
  );
};

export default Pressure;
