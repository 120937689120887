import {useState, useCallback, useEffect, ChangeEvent} from 'react';
import Typography from '../../../../components/elements/typography';
import './base.style.css';
import Table from '../../../../components/table';
import {
  representTableData,
  convertFromCelciusToFarenheight,
} from '../../../../util';
import {IWaterStation} from 'model/waterStation';
import {chemicals} from '../../../../constants';
import {
  VIPh,
  VIPressure,
  VIChlorine,
  VITurbidity,
  VITemperature,
  VIConductivity,
} from '../../../../assets/icons';
import {compareOptions, Legends} from '../../../../constants';
import {IkeyValues} from 'model/home';
import {IchemicalList, IChemicalMeasurements} from 'model/chemicalRecord';
import {getChemicalRecordByInterval} from 'api/chemicalRecord';
import {AxiosResponse} from 'axios';
import RollOver from './rollOver';

interface Itrend {
  value: string;
  rate: string;
}
interface IOverviewTrend {
  parameter: string;
  value: string;
  trend: Itrend;
  unit: string;
}
const unitRate = (type: string) => <div className={`rate-is-${type}`} />;
const IconSize = {
  width: 30,
  height: 30,
};

const heading: Array<{
  title: string;
  description: string;
}> = [
  {
    title: 'Parameter',
    description:
      'A list of parameters for a given location with the acceptable range provided by the customer.',
  },
  {
    title: 'Value',
    description: 'The current value reading of each parameter.',
  },
  {
    title: 'Unit',
    description: 'The unit of measurement.',
  },
  {
    title: 'Trend',
    description:
      'The trend represents value the data is trending based on the time range selected.',
  },
];

const iconFunc = (name: string) => {
  switch (name) {
    case 'pressure':
      return <VIPressure className="svg-pressure-active" style={IconSize} />;
    case 'turbidity':
      return <VITurbidity className="svg-turbidity-active" style={IconSize} />;
    case 'chlorine':
      return <VIChlorine className="svg-chlorine-active" style={IconSize} />;
    case 'ph':
      return <VIPh className="svg-ph-active" style={IconSize} />;
    case 'water temperature':
      return (
        <VITemperature className="svg-temperature-active" style={IconSize} />
      );
    case 'conductivity':
      return (
        <VIConductivity className="svg-conductivity-active" style={IconSize} />
      );
  }
};

const getOverview = (
    overviewData: IOverviewTrend[],
    chemicalMeasurementDisplay: any,
    handleSelection: (e: ChangeEvent<HTMLInputElement>) => void,
) => {
  const filteredLatestReading = overviewData.filter((data) => data.value);

  return representTableData(filteredLatestReading, (res, index) => {
    return {
      Parameter: (
        <div className="flex items-center">
          {index === 0 ? (
            <RollOver text="You can select which parameters you want to see on the graph.">
              <input
                type="checkbox"
                value={res.parameter}
                checked={chemicalMeasurementDisplay[res.parameter]}
                style={{marginRight: 10}}
                onChange={handleSelection}
              ></input>
            </RollOver>
          ) : (
            <input
              type="checkbox"
              value={res.parameter}
              checked={chemicalMeasurementDisplay[res.parameter]}
              style={{marginRight: 10}}
              onChange={handleSelection}
            ></input>
          )}
          {iconFunc(res.parameter)}
          <Typography className="font-bold text-textGrey ml-2">
            {Legends[res.parameter as IchemicalList].text}
          </Typography>
        </div>
      ),
      Value: <Typography className="font-bold">{res.value}</Typography>,
      Unit: res.unit || res.parameter,
      Trend: (
        <div className="flex items-center">
          <span className="mr-2">{unitRate(res.trend.rate)}</span>
          {res.trend.value}
        </div>
      ),
    };
  });
};

const Overview = ({
  waterStation,
  chemicalMeasurementDisplay,
  handleChemicalDisplaySelection,
}: {
  waterStation: IWaterStation;
  chemicalMeasurementDisplay: any;
  handleChemicalDisplaySelection: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const [selected] = useState<number>(0);
  const chemicalInterval: string[] = Object.keys(compareOptions);
  const [chemicalOverviewData, setData] = useState<IOverviewTrend[]>([]);
  const fetchRecords = useCallback(async () => {
    if (waterStation.sensors.length > 0) {
      const selectedSensor =
        waterStation.sensors[waterStation.activeSensorIndex];
      const selectedInterval: IkeyValues = chemicalInterval[
          selected
      ] as IkeyValues;

      const requests = chemicals.map((chemical) =>
        getChemicalRecordByInterval({
          id: selectedSensor.id,
          chemical: chemical === 'pressure' ? 'internal pressure' : chemical,
          interval: compareOptions[selectedInterval],
        }),
      );
      const resp = await Promise.all(requests);

      const chemicalsWithData: number[] = [];
      const chemicalsWithDataRecord: AxiosResponse<IChemicalMeasurements>[] =
        [];

      resp.forEach((data, key) => {
        chemicalsWithData.push(key);
        chemicalsWithDataRecord.push(data);
      });

      const result: IOverviewTrend[] = chemicalsWithDataRecord.map(
          ({config, data}, key) => {
            let chemical: IchemicalList = `${
              config?.url?.split('/')[4] ?? 'chlorine'
            }` as IchemicalList;

            if (chemical === 'internal pressure') {
              chemical = 'pressure';
            }
            const recordList = data.results.records.filter(
                (record) => record.value !== null,
            );
            const isTemperature: boolean = chemical === 'water temperature';
            const currentVal: number = recordList[0]?.value ?? '';
            const unit: string = recordList[0]?.unit ?? '';
            const formattedUnit: string = unit === 'ppm' ? 'mg/l' : unit;
            const isUnitCelcius: boolean = formattedUnit === '°C';
            // const convertedCurrentVal =
            // isTemperature && isUnitCelcius ?
            //   convertFromCelciusToFarenheight(currentVal) :
            //   currentVal ?
            //   Number(currentVal).toFixed(2) :
            //       '';
            const convertedCurrentVal = Number(currentVal).toFixed(2);
            // const convertedUnit = formattedUnit === '°C' ? '°F'
            //  : formattedUnit;
            const convertedUnit = formattedUnit;
            const d: IOverviewTrend = {
              parameter: chemical,
              value: `${convertedCurrentVal}`,
              trend: {value: '', rate: ''},
              unit: convertedUnit,
            };
            const previousVal = recordList[1]?.value;
            if (currentVal && previousVal) {
              const trend = currentVal - previousVal;
              const convertedTrend =
              isTemperature && isUnitCelcius ?
                Number(convertFromCelciusToFarenheight(currentVal)) -
                  Number(convertFromCelciusToFarenheight(previousVal)) :
                trend;

              d['trend'] = {
                value: `${Math.abs(convertedTrend).toFixed(2)}`,
                rate: `${currentVal > previousVal ? 'up' : 'down'}`,
              };
            }
            return d;
          },
      );
      setData(result);
    }
    // eslint-disable-next-line
  }, [waterStation, selected]);

  useEffect(() => {
    if (waterStation.sensors.length > 0) {
      fetchRecords();
    }
  }, [fetchRecords, waterStation.sensors.length]);

  return (
    <div className="v-card-table-heading  my-table v-card-table no-table-border-bottom">
      <Table
        heading={heading}
        columns={getOverview(
            chemicalOverviewData,
            chemicalMeasurementDisplay,
            handleChemicalDisplaySelection,
        )}
      />
    </div>
  );
};

export default Overview;
